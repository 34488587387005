export const ComplaintsPatientFileSettingsPageConstant = {
    complaint: 'Patient_File_Settings_Complaints.complaint',
    complaints: 'Patient_File_Settings_Complaints.complaints',
    name: 'Patient_File_Settings_Complaints.name',
    description: 'Patient_File_Settings_Complaints.description',
    code: 'Patient_File_Settings_Complaints.code',
    confirm: 'Patient_File_Settings_Complaints.confirm',
    replace: 'Patient_File_Settings_Complaints.replace',
    mainComplaint: 'Patient_File_Settings_Complaints.maincomplaint',
    pendingComplaint: 'Patient_File_Settings_Complaints.pendingcomplaint',
    uniqueCode: 'Patient_File_Settings_Complaints.uniquecode',
    replaceComplaint: 'Patient_File_Settings_Complaints.replacecomplaint',
    isActive: 'Patient_File_Settings_Complaints.isactive',
    complaintsParent: 'Patient_File_Settings_Complaints.complaintsparent'
};
