export const SnomedPageConstant = {
    snomed: 'Snomed.snomed',
    name: 'Snomed.name',
    description: 'Snomed.description',
    code: 'Snomed.code',
    confirm: 'Snomed.confirm',
    replace: 'Snomed.replace',
    mainSnomed: 'Snomed.mainsnomed',
    pendingSnomed: 'Snomed.pendingsnomed',
    uniqueCode: 'Snomed.uniquecode',
    replaceSnomed: 'Snomed.replacesnomed',
    cpt: 'Snomed.cpt',
    icd10: 'Snomed.icd10',
    icd11: 'Snomed.icd11',
    loinc: 'Snomed.loinc',
    unmls: 'Snomed.unmls',
    mappingToCPT: 'Snomed.mappingtocpt',
    mappingToICD10: 'Snomed.mappingtoicd10',
    mappingToICD11: 'Snomed.mappingtoicd11',
    mappingToLOINC: 'Snomed.mappingtoloinc',
    mappingToUNMLS: 'Snomed.mappingtounmls'
};
