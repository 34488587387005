import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const SystemSettings = Loadable(lazy(() => import('views/Settings')));
const Constants = Loadable(lazy(() => import('views/Settings/Constants')));
const Countries = Loadable(lazy(() => import('views/Settings/country')));
const Cities = Loadable(lazy(() => import('views/Settings/city')));
const States = Loadable(lazy(() => import('views/Settings/state')));
const Religion = Loadable(lazy(() => import('views/Settings/religion')));
const Nicknames = Loadable(lazy(() => import('views/Settings/nicknames')));
const MaritalStatus = Loadable(lazy(() => import('views/Settings/maritalStatus')));
const SocialMedia = Loadable(lazy(() => import('views/Settings/socialMedia')));
const MemberCategories = Loadable(lazy(() => import('views/Settings/MemberCategories')));
const Academic = Loadable(lazy(() => import('views/Settings/Academic')));
const Doctrines = Loadable(lazy(() => import('views/Settings/Doctrine')));
const DataSource = Loadable(lazy(() => import('views/Settings/DataSource')));
const Priority = Loadable(lazy(() => import('views/Settings/priority')));
const DataSourceRequests = Loadable(lazy(() => import('views/Settings/DataSourceRequests')));
const Levels = Loadable(lazy(() => import('views/Settings/professional-profile/levels')));
const Courses = Loadable(lazy(() => import('views/Settings/professional-profile/courses')));
const Skills = Loadable(lazy(() => import('views/Settings/professional-profile/skills')));
const Institutions = Loadable(lazy(() => import('views/Settings/professional-profile/institutions')));
const Experiences = Loadable(lazy(() => import('views/Settings/professional-profile/experiences')));
const Study = Loadable(lazy(() => import('views/Settings/academic-profile/study')));
const Department = Loadable(lazy(() => import('views/Settings/academic-profile/department')));
const PatientProfession = Loadable(lazy(() => import('views/Settings/patient/profession')));
const PatientScientific = Loadable(lazy(() => import('views/Settings/patient/scientific')));
const Snomed = Loadable(lazy(() => import('views/Settings/patient/patient-data-settings/snomed')));
const Icd = Loadable(lazy(() => import('views/Settings/patient/patient-data-settings/icd')));
const ComplaintsInPatientSettings = Loadable(lazy(() => import('views/Settings/patient/patient-file-settings/complaint')));

const routes = [
    {
        path: '/settings',
        element: <SystemSettings />
    },
    {
        path: '/dataSources-requests',
        element: <DataSourceRequests />
    },
    {
        path: '/constants',
        element: <Constants />
    },
    {
        path: '/social-medias',
        element: <SocialMedia />
    },
    {
        path: '/marital-statuses',
        element: <MaritalStatus />
    },
    {
        path: '/nicknames',
        element: <Nicknames />
    },
    {
        path: '/countries',
        element: <Countries />
    },
    {
        path: '/cities',
        element: <Cities />
    },
    {
        path: '/states',
        element: <States />
    },
    {
        path: '/religions',
        element: <Religion />
    },
    {
        path: '/doctrines',
        element: <Doctrines />
    },
    {
        path: '/dataSources',
        element: <DataSource />
    },
    {
        path: '/priorities',
        element: <Priority />
    },
    {
        path: '/member-categories',
        element: <MemberCategories />
    },
    {
        path: '/merged-member-categories',
        element: <></>
    },
    {
        path: '/academics',
        element: <Academic />
    },
    {
        path: '/levels',
        element: <Levels />
    },
    {
        path: '/courses',
        element: <Courses />
    },
    {
        path: '/skills',
        element: <Skills />
    },
    {
        path: '/institutions',
        element: <Institutions />
    },
    {
        path: '/experiences',
        element: <Experiences />
    },
    {
        path: '/study',
        element: <Study />
    },
    {
        path: '/department',
        element: <Department />
    },
    {
        path: '/patient-profession',
        element: <PatientProfession />
    },
    {
        path: '/patient-scientific',
        element: <PatientScientific />
    },
    {
        path: '/snomed',
        element: <Snomed />
    },
    {
        path: '/icd',
        element: <Icd />
    },
    {
        path: '/complaints',
        element: <ComplaintsInPatientSettings />
    }
];
export default routes;
