export const IcdPageConstant = {
    icd: 'Icd.icd',
    name: 'Icd.name',
    description: 'Icd.description',
    code: 'Icd.code',
    confirm: 'Icd.confirm',
    replace: 'Icd.replace',
    mainIcd: 'Icd.mainicd',
    pendingIcd: 'Icd.pendingicd',
    uniqueCode: 'Icd.uniquecode',
    replaceIcd: 'Icd.replaceicd',
    cpt: 'Icd.cpt',
    icd10: 'Icd.icd10',
    snomed: 'Icd.snomed',
    loinc: 'Icd.loinc',
    unmls: 'Icd.unmls',
    mappingToCPT: 'Icd.mappingtocpt',
    mappingToICD10: 'Icd.mappingtoicd10',
    mappingToSnomed: 'Icd.mappingtosnomed',
    mappingToLOINC: 'Icd.mappingtoloinc',
    mappingToUNMLS: 'Icd.mappingtounmls'
};
