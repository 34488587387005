import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconNewSection, IconSection } from '@tabler/icons';

// constant
const icons = { IconUserCircle, IconUserPlus, IconUser, IconNewSection, IconSection };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useTranslation } from 'react-i18next';
import { FileTemplatesPageConstant } from 'constant/Translations/file-templates/file-templates-page';
import { ConstantPageConstant } from 'constant/Translations/settings/file-templates/constant-page';
import { DataSourcePageConstant } from 'constant/Translations/settings/file-templates/data-source-page';
import { DataSourceRequestsPageConstant } from 'constant/Translations/settings/file-templates/data-source-requests-page';
import { CountryPageConstant } from 'constant/Translations/settings/address/country-page';
import { CityPageConstant } from 'constant/Translations/settings/address/city-page';
import { StatePageConstant } from 'constant/Translations/settings/address/state-page';
import { SocialMediaPageConstant } from 'constant/Translations/settings/profile/social-media-page';
import { ReligionPageConstant } from 'constant/Translations/settings/profile/religion-page';
import { DoctrinePageConstant } from 'constant/Translations/settings/profile/doctrine-page';
import { MaritalStatusPageConstant } from 'constant/Translations/settings/profile/marital-status-page';
import { NicknamePageConstant } from 'constant/Translations/settings/profile/nickname-page';
import { PatientProfessionPageConstant } from 'constant/Translations/settings/patient/profession';
import { PatientScientificPageConstant } from 'constant/Translations/settings/patient/scientific';
import { SystemSettingsPageConstant } from 'constant/Translations/settings';
import { CommonPageConstant } from 'constant/Translations/common';
import { SnomedPageConstant } from 'constant/Translations/settings/patient/patient-data-settings/snomed';
import { IcdPageConstant } from 'constant/Translations/settings/patient/patient-data-settings/icd';
import { ComplaintsPatientFileSettingsPageConstant } from 'constant/Translations/settings/patient/patient-file-settings/complaint';

const useSettings = () => {
    const { t } = useTranslation();
    return {
        id: 'settings',
        title: 'Settings',
        type: 'group',
        icon: <SettingsRoundedIcon />,
        children: [
            {
                id: 'settings',
                title: t(SystemSettingsPageConstant.settings),
                type: 'item',
                icon: icons.IconSection,
                url: '/settings',
                breadcrumbs: false
            },
            {
                id: 'filetemplate',
                title: t(FileTemplatesPageConstant.fileTemplates),
                type: 'group',
                icon: <LocalHospitalRoundedIcon />,
                children: [
                    {
                        id: 'constants',
                        title: t(ConstantPageConstant.constants),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/constants',
                        breadcrumbs: false
                    },
                    {
                        id: 'DataSources',
                        title: t(DataSourcePageConstant.dataSources),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/dataSources',
                        breadcrumbs: false
                    },
                    {
                        id: 'DataSourcesRequests',
                        title: t(DataSourceRequestsPageConstant.dataSourceRequests),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/dataSources-requests',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'address',
                title: 'Address',
                type: 'group',
                icon: <RoomRoundedIcon />,
                children: [
                    {
                        id: 'countries',
                        title: t(CountryPageConstant.countries),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/countries',
                        breadcrumbs: false
                    },
                    {
                        id: 'states',
                        title: t(StatePageConstant.states),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/states',
                        breadcrumbs: false
                    },
                    {
                        id: 'cities',
                        title: t(CityPageConstant.cities),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/cities',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'personal-information',
                title: 'Personal Information',
                type: 'group',
                icon: <AccountCircleRoundedIcon />,
                children: [
                    {
                        id: 'marital-statuses',
                        title: t(MaritalStatusPageConstant.maritalStatuses),
                        type: 'item',
                        url: '/marital-statuses',
                        icon: icons.IconSection,
                        breadcrumbs: false
                    },
                    {
                        id: 'religions',
                        title: t(ReligionPageConstant.religions),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/religions',
                        breadcrumbs: false
                    },
                    {
                        id: 'beliefs',
                        title: 'Beliefs',
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/beliefs',
                        breadcrumbs: false
                    },
                    {
                        id: 'Doctrines',
                        title: t(DoctrinePageConstant.doctrines),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/doctrines',
                        breadcrumbs: false
                    },
                    {
                        id: 'social-medias',
                        title: t(SocialMediaPageConstant.socialMedias),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/social-medias',
                        breadcrumbs: false
                    },
                    {
                        id: 'nicknames',
                        title: t(NicknamePageConstant.nicknames),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/nicknames',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'professional-profile',
                title: 'Professional Profile',
                type: 'group',
                icon: <IconSection />,
                children: [
                    {
                        id: 'institutions',
                        title: 'Institutions',
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/institutions',
                        breadcrumbs: false
                    },
                    {
                        id: 'levels',
                        title: 'Levels',
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/levels',
                        breadcrumbs: false
                    },
                    {
                        id: 'courses-acquired-skills',
                        title: 'Courses and Acquired Skills',
                        type: 'group',
                        icon: <IconSection />,
                        children: [
                            {
                                id: 'courses',
                                title: 'Courses',
                                type: 'group',
                                icon: <IconSection />,
                                children: [
                                    {
                                        id: 'course-names',
                                        title: 'Course Names',
                                        type: 'item',
                                        icon: icons.IconSection,
                                        url: '/courses',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'skills',
                                title: 'Skills',
                                type: 'group',
                                icon: <IconSection />,
                                children: [
                                    {
                                        id: 'skill-names',
                                        title: 'Skill Names',
                                        type: 'item',
                                        icon: icons.IconSection,
                                        url: '/skills',
                                        breadcrumbs: false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 'experiences',
                        title: 'Experiences',
                        type: 'group',
                        icon: <IconSection />,
                        children: [
                            {
                                id: 'experience-names',
                                title: 'Experience Names',
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/experiences',
                                breadcrumbs: false
                            }
                        ]
                    },
                    {
                        id: 'publications',
                        title: 'Publications',
                        type: 'group',
                        icon: <IconSection />,
                        children: [
                            {
                                id: 'publisher',
                                title: 'Publisher',
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/publisher',
                                breadcrumbs: false
                            },
                            {
                                id: 'place-of-publication',
                                title: 'Place of publication',
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/place-of-publication',
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            },
            {
                id: 'academic-profile',
                title: 'Academic Profile',
                type: 'group',
                icon: <IconSection />,
                children: [
                    {
                        id: 'education',
                        title: 'Education',
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/study',
                        breadcrumbs: false
                    },
                    {
                        id: 'department',
                        title: 'Department',
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/department',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'professional-information',
                    //     title: 'Professional Information',
                    //     type: 'item',
                    //     icon: icons.IconSection,
                    //     url: '/scientific-file',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'languages',
                        title: 'Languages',
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/languages',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'patient',
                title: t(PatientProfessionPageConstant.patient),
                type: 'group',
                icon: <IconSection />,
                children: [
                    {
                        id: 'profession',
                        title: t(PatientProfessionPageConstant.profession),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/patient-profession',
                        breadcrumbs: false
                    },
                    {
                        id: 'scientific',
                        title: t(PatientScientificPageConstant.scientific),
                        type: 'item',
                        icon: icons.IconSection,
                        url: '/patient-scientific',
                        breadcrumbs: false
                    },
                    {
                        id: 'patient-data-settings',
                        title: t(CommonPageConstant.patientDataSettings),
                        type: 'group',
                        icon: <IconSection />,
                        children: [
                            {
                                id: 'snomed',
                                title: t(SnomedPageConstant.snomed),
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/snomed',
                                breadcrumbs: false
                            },
                            {
                                id: 'icd',
                                title: t(IcdPageConstant.icd),
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/icd',
                                breadcrumbs: false
                            }
                        ]
                    }
                    // {
                    //     id: 'patient-file-settings',
                    //     title: t(CommonPageConstant.patientFileSettings),
                    //     type: 'group',
                    //     icon: <IconSection />,
                    //     children: [
                    //         {
                    //             id: 'complaints',
                    //             title: t(ComplaintsPatientFileSettingsPageConstant.complaints),
                    //             type: 'item',
                    //             icon: icons.IconSection,
                    //             url: '/complaints',
                    //             breadcrumbs: false
                    //         }
                    //     ]
                    // }
                ]
            }
        ]
    };
};
export default useSettings;
