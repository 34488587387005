import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const ComingSoon = Loadable(lazy(() => import('views/coming-soon')));
const Patients = Loadable(lazy(() => import('views/administrative-management/patient-management/patient-records')));
const PatientDetails = Loadable(lazy(() => import('views/administrative-management/patient-management/patient-records/patient-details')));
const MemberAndPatientNumbering = Loadable(
    lazy(() => import('views/administrative-management/quality-management/member-and-patient-numbering'))
);
const MasterAppointmentSettings = Loadable(lazy(() => import('views/administrative-management/master-appointment-settings')));

const routes = [
    {
        path: '/scientific-research',
        element: <ComingSoon />
    },
    {
        path: '/training-programs',
        element: <ComingSoon />
    },
    {
        path: '/workshops',
        element: <ComingSoon />
    },
    {
        path: '/educational-courses',
        element: <ComingSoon />
    },
    {
        path: '/supply-distribution-management',
        element: <ComingSoon />
    },
    {
        path: '/transportation-internal-transport-management',
        element: <ComingSoon />
    },
    {
        path: '/inventory-purchasing-management',
        element: <ComingSoon />
    },
    {
        path: '/food-nutrition-services-management',
        element: <ComingSoon />
    },
    {
        path: '/marketing-and-advertising',
        element: <ComingSoon />
    },
    {
        path: '/patient-records',
        element: <Patients />
    },
    {
        path: '/patient-details',
        element: <PatientDetails />
    },
    {
        path: '/member-and-patient-numbering',
        element: <MemberAndPatientNumbering />
    },
    {
        path: '/appointment-settings',
        element: <MasterAppointmentSettings />
    }
];
export default routes;
